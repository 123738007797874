import React from 'react';

const Home = () => {
    return (
        <>
            {/* <!--================Home Banner Area =================--> */}

            <section className="banner-area d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <h1>Welcome to Our<br />
                                Dental Clinic in Gondia</h1>
                            <p>At our clinic, your smile is our top priority. We provide comprehensive dental care for the entire family in a comfortable and friendly environment.</p>
                            <a href="" className="main_btn">Book an Appointment</a>
                            <a href="" className="main_btn_light">Explore Services</a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--================End Home Banner Area =================--> */}


            {/* <!--================ Feature section start =================-->       */}
            <section className="feature-section">
                <div className="container">
                    <div className="row">
                        {/* First Feature */}
                        <div className="col-md-4">
                            <div className="card card-feature text-center text-lg-left">

                                <h3 className="card-feature__title"><span className="card-feature__icon">
                                    <i className="ti-layers"></i>
                                </span>Preventive Dentistry
                                </h3>
                                <p className="card-feature__subtitle">From routine cleanings to thorough exams, we help prevent dental issues before they start.</p>
                            </div>
                        </div>
                        {/* Second Feature */}
                        <div className="col-md-4">
                            <div className="card card-feature text-center text-lg-left">

                                <h3 className="card-feature__title"><span className="card-feature__icon">
                                    <i className="ti-heart-broken"></i>
                                </span>Emergency Dental Care</h3>
                                <p className="card-feature__subtitle">Fast, reliable care when you need it most. For dental emergencies, we are here for you.</p>
                            </div>
                        </div>
                        {/* Third Feature */}
                        <div className="col-md-4">
                            <div className="card card-feature text-center text-lg-left">

                                <h3 className="card-feature__title"><span className="card-feature__icon">
                                    <i className="ti-headphone-alt"></i>
                                </span>Book Online</h3>
                                <p className="card-feature__subtitle">Convenient online booking to help you schedule appointments at your convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================ Feature section end =================-->   */}

            {/* <!--================ Service section start =================-->   */}

            <div className="service-area area-padding-top">
                <div className="container">
                    <div className="area-heading row">
                        <div className="col-md-5 col-xl-4">
                            <h3>Comprehensive<br />
                                Dental Services</h3>
                        </div>
                        <div className="col-md-7 col-xl-8">
                            <p>At our Gondia dental clinic, we offer a range of dental services designed to keep your smile healthy and bright. Our experienced team provides personalized care using the latest dental technology.</p>
                        </div>
                    </div>
                    <div className="row">
                        {/* Service 1 */}
                        <div className="col-md-6 col-lg-4">
                            <div className="card-service text-center text-lg-left mb-4 mb-lg-0">
                                <span className="card-service__icon">
                                    <i className="flaticon-brain"></i>
                                </span>
                                <h3 className="card-service__title">Preventive Care</h3>
                                <p className="card-service__subtitle">Routine checkups, cleanings, and exams to keep your teeth and gums healthy.</p>
                                <a className="card-service__link" href="/preventive-care">Learn More</a>
                            </div>
                        </div>
                        {/* Service 2 */}
                        <div className="col-md-6 col-lg-4">
                            <div className="card-service text-center text-lg-left mb-4 mb-lg-0">
                                <span className="card-service__icon">
                                    <i className="flaticon-tooth"></i>
                                </span>
                                <h3 className="card-service__title">Restorative Dentistry</h3>
                                <p className="card-service__subtitle">From fillings to crowns, we restore your teeth to their natural beauty and function.</p>
                                <a className="card-service__link" href="/restorative-dentistry">Learn More</a>
                            </div>
                        </div>
                        {/* Service 3 */}
                        <div className="col-md-6 col-lg-4">
                            <div className="card-service text-center text-lg-left mb-4 mb-lg-0">
                                <span className="card-service__icon">
                                    <i className="flaticon-face"></i>
                                </span>
                                <h3 className="card-service__title">Cosmetic Dentistry</h3>
                                <p className="card-service__subtitle">Achieve the smile of your dreams with our teeth whitening, veneers, and more.</p>
                                <a className="card-service__link" href="/cosmetic-dentistry">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--================ Service section end =================-->       */}

            {/* <!--================About  Area =================--> */}
            <section className="about-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-6 offset-xl-7 col-xl-5">
                            <div className="about-content">
                                <h4>Dedicated to Your <br />
                                    Smile and Oral Health</h4>
                                <h6>Our team is committed to providing high-quality dental care with compassion and professionalism. From preventive care to complex procedures, we aim to make your experience comfortable and rewarding.</h6>
                                <a className="link_one" href="#">Learn more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================About Area End =================--> */}

            {/* <!--================ Team section start =================-->   */}
            <section className="team-area area-padding">
                <div className="container">
                    <div className="area-heading row">
                        <div className="col-md-5 col-xl-4">
                            <h3>Our Experienced<br />
                                Dentists</h3>
                        </div>
                        <div className="col-md-7 col-xl-8">
                            <p>Our team of experienced dentists in Gondia is dedicated to providing exceptional dental care. With a focus on patient comfort and satisfaction, we work together to ensure you receive the best treatment.</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        {/* Doctor Profile */}
                        {/* <div className="col-12 col-md-6 col-lg-4">
                            <div className="card card-team">
                                <img className="card-img rounded-0" src="img/team/1.jpg" alt="" />
                                <div className="card-team__body text-center">
                                    <h3><a href="#">Dr. Rahul Shukla</a></h3>
                                    <p>Dentist</p>
                                    <div className="team-footer d-flex justify-content-between">
                                        <a className="dn_btn" href=""><i className="ti-mobile"></i>+91 98765 43210</a>
                                        <ul className="card-team__social">
                                            <li><a href="#"><i className="ti-facebook"></i></a></li>
                                            <li><a href="#"><i className="ti-twitter-alt"></i></a></li>
                                            <li><a href="#"><i className="ti-instagram"></i></a></li>
                                            <li><a href="#"><i className="ti-skype"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!--================ Team section end =================-->   */}

            {/* <!--================ appointment Area Starts =================--> */}
            <section className="appointment-area">
                <div className="container">

                    <div className="appointment-inner">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5 offset-lg-1">
                                <h3>Have Some Questions?</h3>
                                <div className="accordion" id="accordionExample">

                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    How often should I visit the dentist for a checkup?
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                                It is recommended to visit the dentist every six months for a routine checkup and cleaning. Regular visits help maintain oral health and catch any issues early.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    What should I do in a dental emergency?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                                If you have a dental emergency, such as a toothache, broken tooth, or lost filling, please contact our clinic immediately. We offer same-day appointments for emergencies.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Do you accept insurance?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Yes, we accept a variety of dental insurance plans. Please contact our office for more information about your specific plan.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    What payment options are available?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="card-body">
                                                We offer several payment options, including cash and UPI. Our team can help you find a payment solution that fits your budget.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header" id="headingFive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    How can I improve my oral hygiene at home?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Maintaining good oral hygiene includes brushing twice a day, flossing daily, and using mouthwash. Our team can provide personalized tips during your visit.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="appointment-form">
                                    <h3>Make an Appointment</h3>
                                    <form action="#">
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <input type="text" placeholder="Your Name" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Your Name'} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" placeholder="Your Email" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Your Email'} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Message</label>
                                            <textarea name="message" cols="20" rows="7" placeholder="Message" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Message'} required></textarea>
                                        </div>
                                        <a href="#" className="main_btn">Make an Appointment</a>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* <!--================ appointment Area End =================--> */}

            {/* <!-- ================ testimonial section start ================= -->       */}
            <section className="testimonial">
                <div className="container">
                    <div className="testi_slider owl-carousel owl-theme">
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes1.jpg" alt="" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “Dr. Sharma and her team are wonderful! They made me feel comfortable and took the time to explain every procedure. Highly recommend this clinic.”
                                    </p>
                                    <h4>- Priya Patel -</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes1.jpg" alt="" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “I had a great experience at this dental clinic. The staff is friendly, and they provided excellent care.”
                                    </p>
                                    <h4>- Rahul Singh -</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="testi_item">
                                <div className="testimonial_image">
                                    <img src="img/elements/tes1.jpg" alt="" />
                                </div>
                                <div className="testi_item_content">
                                    <p>
                                        “Best dental clinic in Gondia! They helped me overcome my fear of the dentist.”
                                    </p>
                                    <h4>- Anita Desai -</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ================ testimonial section end ================= -->     */}

            {/* <!-- ================ Hotline Area Starts ================= -->   */}
            <section className="hotline-area text-center area-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2>Emergency hotline</h2>
                            <span>(+91) 90965 69324</span>
                            <p className="pt-3">We provide 24/7 customer support. Please feel free to contact us <br />for any emergency cases.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- ================ Hotline Area End ================= -->   */}

            {/* <!--================ Start Brands Area =================--> */}
            <section className="brands-area background_one">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="owl-carousel brand-carousel">
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/1.png" alt="" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/2.png" alt="" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/3.png" alt="" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/4.png" alt="" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/5.png" alt="" />
                                    </div>
                                </div>
                                {/* <!-- single-brand --> */}
                                <div className="single-brand-item d-table">
                                    <div className="d-table-cell">
                                        <img src="img/brand/3.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--================ End Brands Area =================--> */}
        </>
    )
};

export default Home;
