import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <>
      {/* <!--================Home Banner Area =================--> */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Our Services</h2>
                <p>Comprehensive Dental Care for a Healthy Smile</p>
              </div>
              <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/services">Services</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}

      {/* <!--================Service Area =================--> */}
       {/* <!--================Service Area =================--> */} 
    <section className="service-area area-padding-top"> 
      <div className="container"> 
       
        <div className="row">
          {/* Service 1: Preventive Care */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-brushing-teeth"></i>
                </div>
                <h4 className="card-title">Preventive Care</h4>
                <p className="card-text">
                  Routine checkups, cleanings, and exams to maintain your oral health and prevent dental issues.
                </p>
              </div>
              
            </div>
          </div>
          
          {/* Service 2: Restorative Dentistry */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-cavity"></i>
                </div>
                <h4 className="card-title">Restorative Dentistry</h4>
                <p className="card-text">
                  Treatments such as fillings, crowns, bridges, and implants to restore the function and appearance of your teeth.
                </p>
              </div>
              <div className="card-footer bg-transparent border-top-0">
                
              </div>
            </div>
          </div>
          
          {/* Service 3: Cosmetic Dentistry */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-teeth"></i>
                </div>
                <h4 className="card-title">Cosmetic Dentistry</h4>
                <p className="card-text">
                  Enhance your smile with teeth whitening, veneers, bonding, and other cosmetic procedures tailored to your needs.
                </p>
              </div>
              <div className="card-footer bg-transparent border-top-0">
                
              </div>
            </div>
          </div>
          
          {/* Service 4: Orthodontics */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-braces"></i>
                </div>
                <h4 className="card-title">Orthodontics</h4>
                <p className="card-text">
                  Straighten your teeth and improve your bite with braces, Invisalign, and other orthodontic solutions.
                </p>
              </div>
              <div className="card-footer bg-transparent border-top-0">
                
              </div>
            </div>
          </div>
          
          {/* Service 5: Emergency Care */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-emergency"></i>
                </div>
                <h4 className="card-title">Emergency Care</h4>
                <p className="card-text">
                  Prompt and effective treatment for dental emergencies such as toothaches, broken teeth, and more.
                </p>
              </div>
              <div className="card-footer bg-transparent border-top-0">
                
              </div>
            </div>
          </div>
          
          {/* Service 6: Pediatric Dentistry */}
          <div className="col-md-6 col-lg-4 mb-4">
            <div className="card single-service text-center h-100">
              <div className="card-body">
                <div className="service-icon mb-3">
                  <i className="flaticon-child"></i>
                </div>
                <h4 className="card-title">Pediatric Dentistry</h4>
                <p className="card-text">
                  Specialized dental care for children to ensure healthy smiles from an early age.
                </p>
              </div>
              <div className="card-footer bg-transparent border-top-0">
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    {/* <!--================Service Area End =================--> */}
      {/* <!--================Service Area End =================--> */}

      {/* <!--================ Appointment Area Start =================--> */}
      <section className="appointment-area area-padding-top">
        <div className="container">
          <div className="appointment-inner">
            <div className="row">
              <div className="col-sm-12 col-lg-5 offset-lg-1">
                <h3>Have Some Questions?</h3>
                <div className="accordion" id="accordionExample">
                  {/* FAQ 1 */}
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What services do you offer?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        We offer a wide range of dental services including preventive care, restorative treatments, cosmetic dentistry, orthodontics, and emergency dental care to ensure your oral health is maintained at its best.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 2 */}
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How can I schedule an appointment?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        You can easily schedule an appointment by clicking the "Book an Appointment" button on our website, calling our office directly, or visiting us in person during our working hours.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 3 */}
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Do you accept insurance?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Yes, we accept a variety of dental insurance plans. Please contact our office to verify if your insurance is accepted and to understand your coverage details.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 4 */}
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What are your office hours?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Our office is open Monday through Friday from 9:00 AM to 7:00 PM, and Saturdays from 9:00 AM to 1:00 PM. We are closed on Sundays.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 5 */}
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How do I prepare for my dental appointment?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Please arrive a few minutes early for your appointment, bring any necessary dental records or insurance information, and inform us of any medications you are currently taking or any dental concerns you may have.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Appointment Form */}
              <div className="col-lg-5">
                <div className="appointment-form">
                  <h3>Make an Appointment</h3>
                  <form
                    className="form-contact contact_form"
                    action="contact_process.php"
                    method="post"
                    id="contactForm"
                    noValidate="novalidate"
                  >
                    <div className="form-group">
                      <label htmlFor="name">Full Name</label>
                      <input
                        className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Your Name"
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = 'Your Name')}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Your Email Address"
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = 'Your Email Address')}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Subject</label>
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Subject"
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = 'Subject')}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        placeholder="Enter your message"
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = 'Enter your message')}
                        required
                      ></textarea>
                    </div>
                    <div className="form-group mt-3">
                      <button type="submit" className="button button-contactForm">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            
          </div>
          </div>
        </section>
        {/* <!-- ================ contact section end ================= --> */}

        {/* <!--================ Start Brands Area =================--> */}
        <section className="brands-area background_one">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="owl-carousel brand-carousel">
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/1.png" alt="Partner 1" />
                    </div>
                  </div>
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/2.png" alt="Partner 2" />
                    </div>
                  </div>
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/3.png" alt="Partner 3" />
                    </div>
                  </div>
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/4.png" alt="Partner 4" />
                    </div>
                  </div>
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/5.png" alt="Partner 5" />
                    </div>
                  </div>
                  {/* <!-- single-brand --> */}
                  <div className="single-brand-item d-table">
                    <div className="d-table-cell">
                      <img src="img/brand/6.png" alt="Partner 6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--================ End Brands Area =================--> */}
      </>
    );
  };
  
  export default Services;
